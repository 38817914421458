.App {
  text-align: center;
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  width: auto;
  margin: 0;
  min-height: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;

}


.imageContainer {
  position: relative;
  width: 100%;
}

.image {
  left: 0;
  top: 0;
  position: relative;
  max-width: 100%;
}

.text1 {
  z-index: 100;
  position: absolute;
  font-size: 5vw;
  left: 2vw;
  top: 15vw;
}

.text2 {
  z-index: 100;
  position: absolute;
  font-size: 3vw;
  left: 2vw;
  top: 20vw;
}

/* Rounded border */
hr.rounded {
  border-top: solid #abdbe3;
  border-radius: 3px;
}

hr.divider {
  border-top: solid black;
  border-radius: 3px;
  width: 75%;
  margin: auto;
}

.roundBorder {
  border: 2px solid #abdbe3;
  border-radius: 8px;
}

.cn {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}
